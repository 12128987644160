.m1 {
  display: block;
  display: flex;
  flex-direction: column;
}
.main1 {
  display: flex;
  flex-direction: row;
 
  width: 100%;
}

nav {
  text-align:center;
  padding: 0;
  margin: 0;
  height: 60px;
  position:relative;
}

nav ul {
  background: #ffff;
  list-style:none;
 
  margin: 0;
  height: "auto";

}


nav ul li {
  display: inline-block;
}


nav ul li a {
  color:#333333;
  display:block;
  padding:0px 40px;
  text-decoration:none;
  float: left;
  height: 30px;
   line-height: 30px;
}

nav ul li:hover {
  background: #333333;
}


nav ul li:hover > a{
    color:#FFFFFF;
}

nav ul li:hover > ul {
  display:block;
}

nav ul ul {
  background: #FFFFFF;
  padding:0;
  text-align: center;
  display:none;
   width: 100%;
  position: absolute;
  top: 60px;
  left: -25px;
  
}
.navs{
  color:#333333;
  display:block;
  padding:0px 10px;
  text-decoration:none;
  float: left;
  height: 60px !important;
   line-height: 60px !important;
}


