.image_slide:hover {
  padding: 5px;
}
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.gallery-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: 10px;
}
